// Use this for default Icomoon

@include fontFaceV2(
  $fontName: "icomoon",
  $fileName: "icomoon",
  $types: (
    woff,
    ttf,
    svg,
    eot,
  ),
  $style: normal
);

@include fontFaceV2(
  $fontName: "Noto Sans JP",
  $fileName: "noto-sans-jp-v25-latin",
  $weights: (
    "100",
    "300",
    "400",
    "500",
  ),
  
  $types: (
    woff,
    woff2,
    svg,
    eot,
  ),
);
@include fontFaceV2(
  $fontName: "Pinyon Script",
  $fileName: "pinyon-script-v10-latin",
  $weights: (
    "400",
  ),
  $types: all
);
@include fontFaceV2( $fontName: "Libre Baskerville", $fileName: "libre-baskerville-v7-latin", $weights: ("400"), $types: all);