#pageWrap {
  padding-top: 14rem;
  @include breakpoint(small) {
    padding-top: rem(160px);
  }
  overflow: hidden;
  transition: 300ms;

  body.cmsBackend & {
    @include breakpoint(large) {
      padding-top: 20rem;
    }
  }

  .hiddenScroll & {
    filter: blur(3px);
  }

  @include breakpoint(small) {
    padding-top: 90px;
  }

  @include breakpoint(medium) {
    padding-top: rem(110px);
  }

  @include breakpoint(huge) {
    padding-top: rem(190px);
  }

  body:not(.index) & {
    padding-top: rem(160px);

    @include breakpoint(small) {
      padding-top: 90px;
    }

    @include breakpoint(giant) {
      padding-top: rem(94px);
    }
  }
}

.padding-outter {
  padding: 4rem 0;

  @include breakpoint(small) {
    padding: 7rem 0;
  }

  @include breakpoint(medium) {
    padding: 8rem 0;
  }

  @include breakpoint(giant) {
    padding: 12rem 0;
  }

  @include breakpoint(cut) {
    padding: rem(160px) 0;
  }
}

.no-margin {
  margin-bottom: 0;
}
.outter-img {
  position: relative;
  z-index: 1;
  border: 16px solid white;
  border-image: url(/images/layout/border-image.jpg) 26;
  @include breakpoint(tiny) {
    border-image: url(/images/layout/border-image.jpg) 22;
  }
  @include breakpoint(small) {
    border-image: url(/images/layout/border-image.jpg) 18;
  }

  img {
    z-index: 2;
    position: relative;
  }
}

// HEADER
//////////////////////////////

#header {
  width: 100%;

  body.index & {
    position: relative;
    z-index: 9;
  }

  body:not(.index) & {
    padding: 3rem 0;

    @include breakpoint(medium) {
      padding: 0;
    }
  }

  > .row {
    align-items: flex-end;

    body.cmsBackend & {
      display: none;
    }

    @include breakpoint(huge) {
      align-items: flex-start;
    }

    body:not(.index) & {
      @include breakpoint(small) {
        align-items: center;
      }
    }
  }

  .right {
    body.index & {
      padding-top: 3rem;

      @include breakpoint(small) {
        padding-top: 0;
      }

      @include breakpoint(large) {
        padding-right: 2rem;
        padding-bottom: 1rem;
      }

      @include breakpoint(giant) {
        padding-right: 3.5rem;
      }

      @include breakpoint(huge) {
        padding-bottom: rem(40px);
        padding-right: rem(50px);
        padding-left: rem(15px);
        padding-top: rem(170px);
      }

      @include breakpoint(full) {
        padding-bottom: rem(60px);
        padding-right: rem(75px);
        padding-left: rem(90px);
      }
    }

    body:not(.index) & {
      @include breakpoint(small) {
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: rem($baseGap);
        padding-bottom: 0;
        padding-top: 0;
      }

      @include breakpoint(large) {
        display: none;
      }
    }

    .branding {
      display: block;
      max-width: rem(200px);
      width: 100%;
      height: auto;
      margin: 0 auto;
      position: relative;
      z-index: 1;

      body:not(.index) & {
        @include breakpoint(small) {
          margin: 0 !important;
          max-width: rem(150px);
        }

        @include breakpoint(large) {
          display: none;
        }
      }

      @include breakpoint(small, max) {
        position: absolute;
        left: 50%;
        top: -85px;
        transform: translateX(-50%);
        body:not(.index) & {
          top: 0;
        }
      }

      @include breakpoint(small) {
        max-width: rem(150px);
        margin-top: 4rem;
        margin-bottom: 2.5rem;
      }

      @include breakpoint(large) {
        max-width: rem(200px);
      }

      @include breakpoint(huge) {
        max-width: rem(250px);
      }

      @include breakpoint(full) {
        margin-bottom: rem(67px);
        max-width: rem(324px);
      }
    }

    p {
      text-align: justify;
      text-align-last: center;

      @include breakpoint(large) {
        font-size: rem(19px);
        line-height: rem(34px);
      }
    }

    ul {
      &.socials {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        margin-top: 3rem;

        body:not(.index) & {
          margin-top: 6rem;

          @include breakpoint(small) {
            margin-top: 0;
          }

          @include breakpoint(large) {
            display: none;
          }
        }

        @include breakpoint(large) {
          margin-bottom: 0;
        }

        @include breakpoint(huge) {
          margin-top: rem(70px);
        }

        @include breakpoint(full) {
          margin-top: rem(110px);
        }

        li {
          &:first-child {
            width: 100%;
            text-align: center;
            margin-bottom: 0.7rem;


            body:not(.index) & {
              @include breakpoint(small) {
                margin-bottom: 0;
                margin-right: rem(24px);
                width: auto;
              }

              span {
                display: none;
              }
            }

            @include breakpoint(large) {
              margin-bottom: 0;
              margin-right: rem(24px);
              width: auto;
            }

            @include breakpoint(giant) {
              margin-right: rem(34px);
            }
          }

          &:not(:first-child):not(:last-child) {
            margin-right: rem(14px);
          }

          a {
            body:not(.index) & {
              @include breakpoint(giant) {
                display: flex;
                align-items: center;
              }
            }

            img {
        background-color: $light !important;

              body:not(.index) & {
                @include breakpoint(giant) {
                  width: rem(20px);
                  height: rem(20px);
                }
              }

              &[data-src] {
                background: $light !important;
        
                    }
            }
            &:hover {
              opacity: 0.7;
            }

            
          }
        }
      }
    }
  }
}

// MAIN CONTENT
//////////////////////////////

main {
  display: block;
  position: relative;

  body:not(.index) & {
    &.isOnScreen {
      &:after,
      &:before {
        content: "";
        display: block;
        position: absolute;
        height: rem(400px);
        width: rem(240px);
        opacity: 0.5;
        z-index: -1;

        @include breakpoint(tiny) {
          height: rem(520px);
          width: rem(470px);
        }

        @include breakpoint(medium) {
          height: rem(720px);
          width: rem(670px);
        }

        @include breakpoint(large) {
          width: 460px;
        }

        @include breakpoint(huge) {
          height: rem(980px);
          width: rem(590px);
        }
      }
    }

    @keyframes pulse {
      0% {
        opacity: 0.5;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.5;
      }
    }

    &.isOnScreen {
      &:after {
        right: rem(-130px);
        top: -9%;
        animation: pulse 2s ease-out;
        animation-iteration-count: infinite;
        background: url("/images/layout/glitter-1-small.jpg") no-repeat center /
          contain;
        z-index: -1;

        @include breakpoint(tiny) {
          right: rem(-230px);
        }

        @include breakpoint(medium) {
          right: rem(-280px);
          top: -10%;
        }

        @include breakpoint(large) {
          background: url("/images/layout/glitter-1.png") no-repeat center /
            contain;
          right: rem(-310px);
        }
      }

      &:before {
        left: 0;
        bottom: -30%;
        background: url("/images/layout/glitter-2-small.jpg") no-repeat center /
          contain;
        z-index: -1;

        @include breakpoint(tiny) {
          left: -35%;
        }

        @include breakpoint(large) {
          background: url("/images/layout/glitter-2.png") no-repeat center /
            contain;
          left: rem(-190px);
        }
      }
    }
  }

  .first-cont {
    position: relative;
    padding-top: 8rem;
    padding-bottom: 5rem;
    transition: all 1500ms linear;
    z-index: 2;
    position: relative;

    body.index & {
      &.cmsBackend {
        display: none;
      }
    }

    body:not(.index) & {
      padding-top: 5rem;

      @include breakpoint(small) {
        padding-top: 7rem;
      }

      @include breakpoint(large) {
        padding-top: 10rem;
      }

      @include breakpoint(huge) {
        padding-top: rem(180px);
        padding-bottom: rem(180px);
      }
    }
    body.weddingDresses & {
      padding-top: 0;
      padding-bottom: 2rem;
    }
    @include breakpoint(small) {
      padding-top: 11rem;
      padding-bottom: 7rem;
    }

    @include breakpoint(large) {
      padding-top: 17rem;
      padding-bottom: 10rem;
    }

    @include breakpoint(huge) {
      padding-top: rem(300px);
      padding-bottom: rem(240px);
    }

    h1 {
      @include breakpoint(small) {
        margin-bottom: 4rem !important;
      }

      br {
        @include breakpoint(tiny, max) {
          display: none;
        }
      }
    }

    body.index & {
      &.isOnScreen {
        &:after,
        &:before {
          content: "";
          display: block;
          position: absolute;
          height: rem(400px);
          width: rem(240px);
          z-index: -1;

          @include breakpoint(tiny) {
            height: rem(520px);
            width: rem(470px);
          }

          @include breakpoint(medium) {
            height: rem(720px);
            width: rem(670px);
          }

          @include breakpoint(large) {
            width: 460px;
          }

          @include breakpoint(huge) {
            height: rem(980px);
            width: rem(590px);
          }
        }

        @keyframes pulse {
          0% {
            opacity: 0.5;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0.5;
          }
        }

        &:after {
          right: rem(-130px);
          top: -9%;
          animation: pulse 2s ease-out;
          animation-iteration-count: infinite;
          opacity: 1;
          background: url("/images/layout/glitter-1-small.jpg") no-repeat center /
            contain;

          @include breakpoint(tiny) {
            right: rem(-230px);
          }

          @include breakpoint(medium) {
            right: rem(-280px);
            top: -2%;
          }

          @include breakpoint(large) {
            background: url("/images/layout/glitter-1.png") no-repeat center /
              contain;
            top: rem(100px);
            right: rem(-130px);
          }
        }

        &:before {
          left: 0;
          bottom: -20%;
          background: url("/images/layout/glitter-2-small.jpg") no-repeat center /
            contain;

          @include breakpoint(tiny) {
            left: -35%;
          }

          @include breakpoint(large) {
            background: url("/images/layout/glitter-2.png") no-repeat center /
              contain;
            left: rem(-100px);
          }
        }
      }
    }

    h1 {
      text-align: center;

      @include breakpoint(huge) {
        margin-bottom: rem(80px);
      }

      &.more-margin {
        margin-bottom: 4rem !important;

        @include breakpoint(giant) {
          margin-bottom: 7rem !important;
        }
      }
    }

    p {
      text-align: justify;
    }
  }

  .teaser-cont {
    z-index: 9;
    position: relative;

      &.isOnScreen {
        &:before {
          content: "";
          position: absolute;
          right: -9%;
          z-index: 0;
          display: block;
          opacity: 0.08;
          width: rem(300px);
          height: rem(400px);
          bottom: 0;
          background: url("/images/layout/flower-bg-2-small.jpg") no-repeat
            center / contain;

          @include breakpoint(tiny) {
            width: rem(400px);
          }

          @include breakpoint(small) {
            
          }

          @include breakpoint(large) {
            background: url("/images/layout/flower-bg-2.png") no-repeat center /
              contain;
            height: rem(1095px);
            width: rem(980px);
           
          }
        }


    @include breakpoint(huge) {
      padding-bottom: rem(165px);
    }

    .swiper-container {
      width: 100%;

      .swiper-slide {
        img {
          margin-bottom: 0;
        }
        .swiper-lazy {
          &:not(.swiper-lazy-loaded) {
            height: 120px;
            .cmsBackend &{
              width: auto;
            }
          }
        }
      }
    }

    ul {
      margin-bottom: 3.5rem;
      position: relative;

      &.isOnScreen {
        li {
          opacity: 1;
          visibility: visible;
          transform: translateX(0);
        }
      }

      body.cmsBackend & {
        display: none;
      }

      @include breakpoint(large) {
        display: flex;
        flex-flow: row;
        margin-bottom: rem(135px);
      }

    
        li {
          &:nth-of-type(1) {
            a {
              &:after {
                background-image: url("/images/layout/brides-maids-small.jpg");

                @include breakpoint(medium) {
                  background-image: url("/images/layout/brides-maids.jpg");
                }
              }
            }
          }

          &:nth-of-type(2) {
            a {
              &:after {
                background-image: url("/images/layout/bride-detail-small.jpg");

                @include breakpoint(medium) {
                  background-image: url("/images/layout/bride-detail.jpg");
                }
              }
            }
          }

          &:nth-of-type(3) {
            a {
              &:after {
                background-image: url("/images/layout/wedding-couple-small.jpg");

                @include breakpoint(medium) {
                  background-image: url("/images/layout/wedding-couple.jpg");
                }
              }
            }
          }
        }
      }

      li {
        display: flex;
        flex-flow: column;
        padding-top: 8rem;
        opacity: 0;
        transform: translateX(-40px);
        transition: 900ms;
        transition-delay: 400ms;

        &:nth-of-type(2) {
          transition-delay: 700ms;
        }

        &:nth-of-type(3) {
          transition-delay: 1400ms;
        }

        @include breakpoint(tiny) {
          padding-top: 11rem;
        }

        @include breakpoint(small) {
          padding-left: 30%;
          overflow: hidden;
          padding-top: 0;
        }

        @include breakpoint(large) {
          padding-top: rem(314px);
          padding-left: 0;
        }

        @include breakpoint(large, max) {
          flex-flow: row;
        }

        @include breakpoint(cut) {
          padding-top: rem(388px);
        }

        &:not(:last-of-type) {
          @include breakpoint(small) {
            margin-bottom: rem(4px);
          }

          @include breakpoint(large) {
            margin-bottom: 0px;
          }
        }

        &:nth-of-type(2) {
          @include breakpoint(small) {
            padding-left: 0%;
            padding-right: 30%;
            overflow: hidden;
            padding-top: 0;
          }

          @include breakpoint(large) {
            padding-top: 0;
            padding-bottom: rem(314px);
            padding-right: 0;
          }

          @include breakpoint(cut) {
            padding-bottom: rem(388px);
          }

          a {
            &:after {
              @include breakpoint(small) {
                bottom: auto;
                top: 0;
                left: 100%;
                height: 100%;
                right: auto;
              }

              @include breakpoint(large) {
                bottom: auto;
                top: 100%;
                left: 0;
                height: rem(314px);
              }

              @include breakpoint(cut) {
                height: rem(388px);
              }
            }
          }
        }

        a {
          background: $medium;
          display: block;
          position: relative;
          padding: rem(40px);
          font-size: rem(14px);
          line-height: rem(23px);

          &:hover {
            span {
              &.read-more {
                transform: translateY(-10px);
              }
            }

            &:before {
              border-color: $beige-light;
            }
          }

          @include breakpoint(tiny) {
            padding: rem(60px);
          }

          @include breakpoint(large) {
            padding: 60px 48px 48px rem(48px);
            min-height: rem(314px);
            display: flex;
            flex-flow: column;
            justify-content: flex-end;
          }

          @include breakpoint(huge) {
            padding-left: rem(75px);
            padding-right: rem(75px);
            font-size: rem(15px);
          }

          @include breakpoint(cut) {
            font-size: rem(16px);
            padding: 97px 97px rem(75px);
            min-height: 388px;
          }

          &:before {
            content: "";
            height: calc(100% - 25px);
            width: calc(100% - 25px);
            border: 2px solid $beige;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            transition: 300ms ease-in-out;

            @include breakpoint(large) {
              border: 4px solid $beige;
            }
          }

          &:after {
            content: "";
            height: 8rem;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 100%;
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;

            @include breakpoint(tiny) {
              height: 11rem;
            }

            @include breakpoint(small) {
              width: 43%;
              bottom: auto;
              top: 0;
              right: 100%;
              height: 100%;
              left: auto;
            }

            @include breakpoint(large) {
              width: 100%;
              left: 0;
              bottom: 100%;
              top: auto;
              right: auto;
              height: rem(314px);
            }

            @include breakpoint(cut) {
              height: rem(388px);
            }
          }

          span {
            display: block;
            text-align: justify;
            text-align-last: center;
            color: $dark;
            transition: 300ms ease-in-out;

            &.headline {
              font-family: $special;
              line-height: 1.2em;
              font-size: rem(26px);
              margin-bottom: 0.4rem;

              @include breakpoint(tiny) {
                font-size: rem(30px);
                margin-bottom: 1.1rem;
              }

              @include breakpoint(huge) {
                font-size: rem(33px);
                margin-bottom: rem(25px);
              }

              @include breakpoint(cut) {
                font-size: rem(44px);
              }
            }

            &.read-more {
              font-style: italic;
              color: $link;
              margin-top: 0.7rem;

              @include breakpoint(tiny) {
                margin-top: 0.9rem;
              }

              @include breakpoint(huge) {
                margin-top: rem(35px);
              }
            }
          }
        }
      }
    }
  }

  .second-cont {
    position: relative;
    padding-top: 4rem;
    padding-bottom: 6rem;

    body.cmsBackend & {
      display: none;
    }

    @include breakpoint(large) {
      padding-bottom: 9rem;
    }

    @include breakpoint(large) {
      padding-bottom: 11rem;
    }

    @include breakpoint(huge) {
      padding-top: 0;
      padding-bottom: rem(240px);
    }

    &.isOnScreen {
      &:before {
        content: "";
        display: block;
        position: absolute;
        height: rem(770px);
        width: rem(470px);

        @include breakpoint(huge) {
          height: rem(980px);
          width: rem(590px);
        }
      }

      &:before {
        background: url("/images/layout/glitter-2-small.jpg") no-repeat center /
          contain;
        left: rem(-200px);
        bottom: -20%;

        @include breakpoint(large) {
          background: url("/images/layout/glitter-2.png") no-repeat center /
            contain;
        }
      }
    }

    > .row {
      align-items: flex-end;
    }

    .large-7 {
      h2 {
        @include breakpoint(giant) {
          margin-bottom: rem(60px);
        }
      }
    }

    .list-part {
      @include breakpoint(large) {
        padding-left: 2.5rem;
      }

      @include breakpoint(huge) {
        padding-left: rem(68px);
      }

      .h1 {
        margin-bottom: 0 !important;
        line-height: 1;
        margin-top: 2rem;
        padding-left: rem($baseGap);
      }

      ul {
        margin-bottom: 0;
        margin-top: rem(-5px);
        padding: rem(30px);

        @include breakpoint(small) {
          margin-top: rem(-7px);
          padding: 45px rem(35px);
        }

        &.special-list {
          border: 3px solid $beige;

          li {
            &:last-child {
              margin-bottom: 0;
            }
          }

          @include breakpoint(small) {
            columns: 2;

            li {
              break-inside: avoid;
            }
          }

          @include breakpoint(large) {
            columns: 1;
          }

          @include breakpoint(huge) {
            columns: 2;
          }
        }
      }
    }
  }

  p,
  ol,
  ul,
  dl,
  table {
    @extend .marginBottom;
  }

  ul:not(.unstyledList),
  dl:not(.unstyledList),
  ol:not(.unstyledList) {
    @extend .styledList;
  }

  img {
    margin-bottom: rem($baseGap * 2);
  }
}

#privacyPolicy {
  a {
    display: inline-block;
    word-break: break-all;
  }
}
.special-btn:nth-of-type(1) {
  margin-right: 1rem;
}

// FOOTER
//////////////////////////////

#footer {
  .middle {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding-top: 4.5rem;
    padding-bottom: 2rem;

    address,
    li {
      text-align: center;
    }

    ul {
      li {
        &:not(:last-child):not(:nth-of-type(2)) {
          margin-bottom: rem(15px);
        }
      }
    }

    .hr {
      flex-basis: rem(1px);
      width: rem(290px);
      margin-top: rem(35px);
      margin-bottom: rem(35px);
    }

    .seal {
      &:hover {
        opacity: 0.7;
      }
    }

    dl {
      dt,
      dd {
        width: auto;
      }
    }

    .seal-text {
      text-align: center;
      margin-top: rem(25px);

      span {
        display: block;

        @include breakpoint(small) {
          font-size: rem(20px);
        }
      }
    }
  }

  .large-3 {
    .outter-img {
      height: 100%;
      margin-bottom: rem(-6px);

      &:after {
        left: auto;
        right: rem(-10px);

        @include breakpoint(large) {
          right: rem(-18px);
        }
      }

      iframe {
        position: relative;
        z-index: 44;

        @include breakpoint(large) {
          height: 100%;
        }
      }
    }
  }

  .navi-add {
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding: 40px 0 rem(110px);

    @include breakpoint(large) {
      justify-content: flex-start;
      padding: 73px 0 rem(55px);
    }

    li {
      a {
        margin-right: 0.8rem;
        color: $dark;

        @include breakpoint(tiny) {
          margin-right: 1.4rem;
        }

        @include breakpoint(large) {
          margin-right: 2.8rem;
        }
      }

      &:hover,
      &.active {
        a {
          color: $link;
        }
      }
    }
  }
}

.image-separator {
  padding-top: 5rem;
  padding-bottom: 5rem;

  @include breakpoint(small) {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  @include breakpoint(giant) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  body.weddingDresses & {
    padding-top: 0;
    padding-bottom: 3rem;
    @include breakpoint(medium) {
      padding-top: 5rem;
    }
  }
}

.middle-col {
  @include breakpoint(large) {
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
  }

  p {
    text-align: justify;
    text-align-last: center;
  }
}

.faq {
  padding-top: 5rem;

  @include breakpoint(small) {
    padding-top: 7rem;
  }

  @include breakpoint(large) {
    padding-top: 9rem;
  }

  @include breakpoint(giant) {
    padding-top: 11rem;
  }

  @include breakpoint(full) {
    padding-top: 15rem;
  }
}

.event-box {
  margin-top: 5rem;

  @include breakpoint(large) {
    margin-top: 0;
  }

  .flex-it {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    .price {
      background: $beige-light;
      padding: 0.7rem;
      font-weight: 600;
      display: inline;
      flex-grow: 1;
      width: 100%;

      @include breakpoint(medium) {
        width: auto;
      }

      @include breakpoint(large) {
        width: 100%;
      }

      @include breakpoint(full) {
        width: auto;
      }

      &.location {
        margin-top: 0.7rem;

        @include breakpoint(medium) {
          margin-left: 0.7rem;
          margin-top: 0;
        }

        @include breakpoint(large) {
          margin-left: 0;
          margin-top: 0.7rem;
        }

        @include breakpoint(full) {
          margin-left: 0.7rem;
          margin-top: 0;
        }
      }
    }
  }
}
.event-text{
  width: 100%;
  margin-top: rem(20px);
}
.story-box {
  .outter-img {
    margin-bottom: 4rem;

    @include breakpoint(small) {
      margin-left: 10%;
      margin-right: 10%;
    }
  }
}

.margin-top-headline {
  margin-top: 6rem;

  @include breakpoint(large) {
    margin-top: 0;
  }
}

.swiper-container.teaser-swiper {
  width: 100%;

  .swiper-slide {
    img {
      margin-bottom: 0;
    }
    .swiper-lazy {
      &:not(.swiper-lazy-loaded) {
        height: 120px;
        .cmsBackend &{
          width: auto;
        }
      }
    }
  }
}

body.scheduleAnAppointment{
  p.red-text{
    color: red;
  }
}
.appointment-frame{
  width: 100%;
  height: rem(1100px);
}
.captchaCenter{
  margin: rem(18px) 0;
}
.event-box{
  .h5 span{
    line-height: 1;
  }
}