// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
  // Default Link Styles
  padding: 1em rem($baseGap);
  text-decoration: none;
  color: $navColor;

  @include breakpoint(large) {
    font-weight: 300;
    padding: 0.7rem rem(12px);
  }

  @include breakpoint(huge) {
    padding: 0.7rem rem($baseGap);
    font-size: 20px;
  }
}
%buttonHover {
  color: $link;

  &:before {
    width: 25px;
  }
}
%buttonActive {
  // Link Active
  color: $link;

  &:before {
    width: 25px;
  }
}
%prevIcon {
  // Icon to previus Submenu
  font-family: $iconFont;
  content: map-get($iconMap, angle-right);
  transform: rotateY(180deg);

  // @include breakpoint($break) {
  // 	transform:rotate(90deg);
  // }
}
%nextIcon {
  // Icon to next Submenu
  font-family: $iconFont;
  content: map-get($iconMap, angle-down);
  color: $beige;
  font-size: 9px;
  margin-left: 0.9rem !important;
  line-height: 1;
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 74px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $light; // background-color
$barColor: $dark; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
  background-color: $navBg;
  z-index: 1001;
  top: 0;

  @include breakpoint($break) {
    width: 100%;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
  }

  > div {
    @include breakpoint($break) {
      max-width: rem($rowMaxWidth);
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      align-items: center;
      padding: 1rem 12px;
    }

    @include breakpoint(giant) {
      padding: 1rem 12px;
    }

    .branding {
      display: none;

      @include breakpoint(large) {
        display: block;
      }

      img {
        width: 110px;
      }
    }

    .fix-it {
      @include breakpoint(medium) {
        position: fixed;
        right: $baseGap;
        top: 90px;
        display: flex;
        flex-flow: row;
        transition: all 300ms;
        padding: 10px;
      }

      @include breakpoint(cut) {
        position: relative;
        right: auto;
        top: auto;
        padding: 0;
      }

      &.small {
        background-color: $light;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);

        @include breakpoint(giant) {
          box-shadow: 0px 0px 0px 0px;
        }
      }
    }

    ul {
      &.socials {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        display: none;

        @include breakpoint(large) {
          margin-bottom: 0;
          margin-right: 1.5rem;
          display: flex;
        }

        @include breakpoint(huge) {
          margin-right: 2.5rem;
        }

        li {
          &:first-child {
            width: 100%;
            text-align: center;
            margin-bottom: 0.7rem;

            body:not(.index) & {
              @include breakpoint(small) {
                margin-bottom: 0;
                margin-right: rem(24px);
                width: auto;
              }

              span {
                display: none;
              }
            }

            @include breakpoint(large) {
              margin-bottom: 0;
              margin-right: rem(24px);
              width: auto;
            }

            @include breakpoint(giant) {
              margin-right: rem(34px);
              display: none;
            }
          }

          &:not(:first-child):not(:last-child) {
            margin-right: rem(14px);
          }

          a {
            body:not(.index) & {
              @include breakpoint(giant) {
                display: flex;
                align-items: center;
              }
            }

            img {
              body:not(.index) & {
                @include breakpoint(giant) {
                  width: rem(20px);
                  height: rem(20px);
                }
              }
            }
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }

    .special-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      display: none;
      &:hover{
        color: $dark;
      }

      @include breakpoint(small) {
        width: auto;
        padding: 5px 15px;
      }

      @include breakpoint(large) {
        display: flex;
        background-color: $beige;
        border-color: $beige;
        font-weight: 600;
        // position: fixed;
        // right: $baseGap;
        // top: 90px;
      }

      @include breakpoint(huge) {
        padding: 20px 36px;

        body:not(.index) & {
          padding: 20px;
        }
      }

      span {
        display: none;

        @include breakpoint(small) {
          display: inline;
        }
      }

      i {
        margin-left: 0;
        margin-right: 0.7rem;
        font-size: 22px;
      }
    }
  }
  nav {
    width: 100%;

    @include breakpoint($break) {
      width: auto;
    }

    @include ul {
      &.navi {
        padding-top: 11rem;

        @include breakpoint(small) {
          padding-right: $baseGap;
        }

        @include breakpoint(medium) {
          padding-right: 0;
          padding-top: 9rem;
        }

        @include breakpoint(large) {
          // margin: 0 auto;
          // z-index: 99999999999999999999;
          // position: relative;
          // max-width: 1620px;
          // column-count: 2;
          padding-top: 0;
          display: flex;
          flex-flow: row;
          gap: 26px;
        }

        // @include breakpoint(giant) {
        // 	padding-top:	11rem;
        // 	padding-left: 48%
        // }

        // @include breakpoint(huge) {
        // 	padding-left: 42%;
        // }

        li {
          @include navButtons {
            @include navButton;
          }

          break-inside: avoid;

          a,
          span {
            justify-content: center;
            display: flex;
            align-items: center;

            @include breakpoint(large) {
              justify-content: flex-start;
            }

            // &:before {
            // 	content:'';
            // 	height: 2px;
            // 	width: 10px;
            // 	background-color: $beige;
            // 	display: inline-block;
            // 	margin-right: .8rem;
            // 	transition: 300ms;
            // }
          }

          @include hasSub {
            @include breakpoint(large) {
              position: relative;
            }

            > span,
            a {
              justify-content: center;

              @include breakpoint(large) {
                justify-content: flex-start !important;
              }
            }

            @include sub {
              background-color: $light;
              padding-top: 11rem;
              display: flex;
              flex-flow: column;

              @include breakpoint(large) {
                // position: relative;
                // height: auto;
                // transform: translateY(0)!important;
                // top: auto;
                // left: auto;
                // padding-top: 0rem;
                // background-color: transparent;
                top: 100%;
                width: auto;
                padding-top: 0;
                left: 0;
                min-width: 12rem;
                box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
                padding-top: 0.9rem;
                padding-bottom: 0.9rem;
              }

              li {
                z-index: 2;

                a,
                span {
                  @include breakpoint(large) {
                    display: block;
                    padding: 0.5rem 1.6rem;
                  }

                  @include breakpoint(huge) {
                    padding: 0.9rem 1.6rem;
                  }
                }

                // @include breakpoint(large) {
                // 	padding-left: 2rem;
                // }

                // &:nth-of-type(1) {
                // 	padding-top: 0;
                // }

                // a, span {
                // 	font-size: 16px;
                // 	font-weight: 300;

                // 	@include breakpoint(large) {
                // 		padding: .5rem rem($baseGap);
                // 		color: lighten($dark, 25%);

                // 		&:before {
                // 			font-family: $iconFont;
                // 			content: map-get($iconMap, angle-right);
                // 			margin-right: .7rem;
                // 			color: $beige;
                // 			font-size: 9px;
                // 		}
                // 	}
                // }
              }

              &:before {
                padding: 1em rem($baseGap);
                background-color: darken($navBg, 6%);
                display: none;
              }
            }
          }
          @include navBack {
            display: flex;
            justify-content: center;
            align-items: center;
            order: 1;
            margin-top: 2rem;

            @include breakpoint(large) {
              display: none;
            }

            &:before {
              font-size: 9px;
              line-height: 1em;
              color: $beige;
              margin-right: 0.9rem;
            }
          }
        }
      }
    }
  }
}

// Quickbar Styles
////
#quickbar {
  background-color: $light;
  color: $dark;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem($baseGap);
  z-index: 1000929292;
  transition: all 300ms ease-in-out;

  @include breakpoint($break) {
    display: none;
  }

  &:after {
    width: 200vw;
    left: 50%;
    top: 0;
    background: $light;
    position: fixed;
    content: "";
    display: block;
    z-index: -1;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
    transform: translate(-50%, -100%);
    transition: 300ms;
    height: 142px;

    @include breakpoint(small) {
      height: 74px;
    }

    @include breakpoint(giant) {
      height: calc(60px + 2 * #{$baseGap});
    }
  }

  &.small,
  .hiddenScroll &,
  body:not(.index) &,
  body.cmsBackend & {
    &:after {
      transform: translate(-50%, 0);
    }

    @include breakpoint(large) {
      top: 0;
    }

    @include breakpoint(giant) {
      top: $baseGap;
    }
  }

  @include breakpoint(giant) {
    width: 49%;
    right: $baseGap;
    left: auto;
    padding: 0;
    background: transparent;
    height: auto;
    top: 90px;
  }

  @include breakpoint(huge) {
    top: 130px;
  }

  @include breakpoint(full) {
    width: 46%;
  }

  @include breakpoint(cut) {
    width: 42%;
  }

  @include breakpoint(cut-after) {
    right: calc((100vw - #{$rowMaxWidth}) / 2);
  }

  .btn-app {
    font-size: 14px;
    padding: 0.4rem;
    background-color: $beige;
    border-color: $beige;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(small, max) {
      position: fixed;
      left: 50%;
      top: 74px;
      transform: translateX(-50%);
      width: calc(100% - #{$baseGap * 2});
    }

    @include breakpoint(small) {
      order: 1;
      flex-shrink: 0;
      margin-right: 1.5rem;
      padding: 0.4rem 0.7rem;
    }

    i {
      margin-right: 0.5rem;
      margin-left: 0;
    }
  }
  li {
    height: 40px;

    @include breakpoint(small) {
      height: 46px;
    }

    @include breakpoint(giant) {
      height: 50px;
    }

    @include breakpoint(full) {
      height: 60px;
    }
    .btn-event {
      height: 40px;
      background-color: $light;
      border-color: $beige-light;
      margin-right: 0;
      padding: 5px;
      @include breakpoint(small) {
        height: 46px;
      }

      @include breakpoint(giant) {
        height: 50px;
      }

      @include breakpoint(full) {
        height: 60px;
      }
    }
  }
  > label {
    @include breakpoint(small) {
      order: 2;
    }
  }

  > ul {
    display: flex;
    width: calc(100% - 60px);
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    margin-right: 0.7rem;

    @include breakpoint(giant) {
      margin-right: 1.5rem;
    }

    @include breakpoint(full) {
      margin-right: 37px;
    }

    > li {
      margin-right: 10px;
      @include breakpoint(tiny) {
        margin-right: 12px;
      }
      &:last-of-type {
        margin-right: 4px;
      }
      &:nth-of-type(1) {
        font-size: 14px;
        display: flex;
        align-items: center;
        font-weight: 400;

        @include breakpoint(giant) {
          font-size: 16px;
        }

        strong {
          @include breakpoint(medium, max) {
            display: none;
          }

          @include breakpoint(small) {
            margin-right: 0.5rem;
          }
        }

        &:before {
          @extend .icon;
          @extend .icon-phone;
          margin-right: 0.5rem;

          @include breakpoint(medium) {
            display: none;
          }
        }
      }

      ul {
        display: flex;
        align-items: center;
        flex-grow: 1;
        width: 100%;

        li {
          width: 40px;
          height: 40px;

          @include breakpoint(small) {
            width: 46px;
            height: 46px;
          }

          @include breakpoint(giant) {
            width: 50px;
            height: 50px;
          }

          @include breakpoint(full) {
            width: 60px;
            height: 60px;
          }

          .btn {
            padding: 5px;
            width: 40px;
            height: 40px;

            @include breakpoint(small) {
              width: 46px;
              height: 46px;
            }

            @include breakpoint(giant) {
              width: 50px;
              height: 50px;
            }

            @include breakpoint(full) {
              width: 60px;
              height: 60px;
            }
          }

          &:nth-of-type(1) {
            position: relative;

            input {
              display: none;

              &:checked {
                * ~ .openings-box,
                ~ .openings-box,
                ~ * .openings-box {
                  transform: translateX(0);
                  opacity: 1;
                }

                * ~ label[for="openings"],
                ~ label[for="openings"],
                ~ * label[for="openings"] {
                  color: $beige;

                  &:after {
                    opacity: 1;
                    visibility: visible;
                  }
                }
              }
            }

            label {
              @extend .btn;

              display: flex;
              justify-content: center;
              align-items: center;

              &:after {
                content: "";
                height: 200vh;
                width: 200vw;
                position: fixed;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                display: block;
                opacity: 0;
                visibility: hidden;
              }

              i {
                margin-left: 0 !important;
              }
            }

            .openings-box {
              background: $light;
              border: 2px solid $beige-light;
              padding: rem(20px) rem(10px);
              display: block;
              position: absolute;
              top: calc(100% + 1rem);
              width: calc(100vw - 20px);
              transform: translateX(300%);
              opacity: 0;
              right: -56px;
              transition: 300ms;
              z-index: 5;
              font-size: rem(14px);
              @include breakpoint(small) {
                min-width: 300px;
                max-width: 300px;
                width: 100%;
                right: 0;
              }
              @include breakpoint(large){
                padding: 2rem;
              }

              dl {
                margin-top: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
  display: flex;
  justify-content: center;

  li {
    margin-right: 1rem;
  }
}

label[for="openings"] span { display:none; }