* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
  background: $light;
  box-sizing: border-box;
  color: $dark;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);
  font-weight: 400;

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style: normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .marginBottom;
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
  */

  &.not-centered {
    text-align: left !important;
  }

  &.more-margin {
    margin-bottom: 4rem !important;

    @include breakpoint(huge) {
      margin-bottom: 7rem !important;
    }
  }
}

h1,
.h1 {
  font-size: rem(17px);
  line-height: 1.5em;
  font-weight: 100;
  margin-bottom: 3.5rem !important;

  @include breakpoint(tiny) {
    font-size: rem(18px);
  }

  @include breakpoint(small) {
    font-size: rem(19px);
  }

  @include breakpoint(medium) {
    font-size: rem(20px);
    margin-bottom: 4.5rem !important;
  }

  @include breakpoint(large) {
    margin-bottom: 5.5rem !important;
    font-size: rem(22px);
  }

  @include breakpoint(giant) {
    font-size: rem(24px);
  }

  @include breakpoint(huge) {
    font-size: rem(27px);
  }

  span {
    color: $beige;
    font-family: $special;
    display: block;
    line-height: 1em;
    font-size: 55px;

    @include breakpoint(tiny) {
      font-size: rem(62px);
    }

    @include breakpoint(small) {
      font-size: rem(68px);
    }

    @include breakpoint(medium) {
      font-size: rem(75px);
    }

    
    @include breakpoint(giant) {
      font-size: rem(97px);
    }

    @include breakpoint(huge) {
      font-size: 104px;
    }
  }
}

h2,
.h2 {
  font-size: rem(16px);
  line-height: 1.5em;
  font-weight: 100;
  margin-bottom: 3.5rem !important;

  @include breakpoint(tiny) {
    font-size: rem(17px);
  }

  @include breakpoint(small) {
    font-size: rem(18px);
  }

  @include breakpoint(medium) {
    font-size: rem(19px);
    margin-bottom: 4.5rem !important;
  }

  @include breakpoint(large) {
    margin-bottom: 5.5rem !important;
    font-size: rem(20px);
  }

  @include breakpoint(giant) {
    font-size: rem(21px);
  }

  @include breakpoint(huge) {
    font-size: rem(22px);
  }

  span {
    color: $beige;
    font-family: $special;
    display: block;
    line-height: 1em;
    font-size: 45px;

    @include breakpoint(tiny) {
      font-size: rem(52px);
    }

    @include breakpoint(small) {
      font-size: rem(58px);
    }

    @include breakpoint(medium) {
      font-size: rem(68px);
    }

    @include breakpoint(large) {
      font-size: rem(75px);
    }

    @include breakpoint(huge) {
      font-size: rem(87px);
    }
  }
}

h3,
.h3 {
  font-size: rem(16px);
  line-height: 1.5em;
  font-weight: 100;
  margin-bottom: 3.5rem !important;

  @include breakpoint(tiny) {
    font-size: rem(17px);
  }

  @include breakpoint(small) {
    font-size: rem(18px);
  }

  @include breakpoint(medium) {
    font-size: rem(19px);
    margin-bottom: 4.5rem !important;
  }

  @include breakpoint(large) {
    margin-bottom: 5.5rem !important;
    font-size: rem(20px);
  }

  @include breakpoint(giant) {
    font-size: rem(21px);
  }

  @include breakpoint(huge) {
    font-size: rem(22px);
  }

  span {
    color: $beige;
    font-family: $special;
    display: block;
    line-height: 0.6em;
    font-size: 30px;

    @include breakpoint(small) {
      font-size: rem(35px);
    }

    @include breakpoint(medium) {
      font-size: rem(45px);
    }

    @include breakpoint(large) {
      font-size: rem(50px);
    }

    @include breakpoint(huge) {
      font-size: rem(55px);
    }
  }
}

h4,
.h4 {
  font-size: rem(16px);
  line-height: 1.5em;
  font-weight: 100;
  margin-bottom: 3.5rem !important;

  @include breakpoint(tiny) {
    font-size: rem(17px);
  }

  @include breakpoint(small) {
    font-size: rem(18px);
  }

  @include breakpoint(medium) {
    font-size: rem(19px);
    margin-bottom: 4.5rem !important;
  }

  @include breakpoint(large) {
    margin-bottom: 5.5rem !important;
    font-size: rem(20px);
  }

  @include breakpoint(giant) {
    font-size: rem(21px);
  }

  @include breakpoint(huge) {
    font-size: rem(22px);
  }

  span {
    color: $dark;
    font-family: $special;
    display: block;
    line-height: 0.6em;
    font-size: 30px;

    @include breakpoint(small) {
      font-size: rem(35px);
    }

    @include breakpoint(medium) {
      font-size: rem(45px);
    }

    @include breakpoint(large) {
      font-size: rem(50px);
    }

    @include breakpoint(huge) {
      font-size: rem(55px);
    }
  }
}

h5,
.h5 {
  font-size: rem(16px);
  line-height: 1.5em;
  font-weight: 100;
  margin-bottom: 2.5rem !important;

  @include breakpoint(tiny) {
    font-size: rem(17px);
  }

  @include breakpoint(small) {
    font-size: rem(18px);
  }

  @include breakpoint(medium) {
    font-size: rem(19px);
    margin-bottom: 3.5rem !important;
  }

  @include breakpoint(large) {
    margin-bottom: 3.5rem !important;
    font-size: rem(20px);
  }

  @include breakpoint(giant) {
    font-size: rem(21px);
  }

  @include breakpoint(huge) {
    font-size: rem(22px);
  }

  span {
    color: $dark;
    font-family: $special;
    display: block;
    line-height: 0.6em;
    font-size: 26px;

    @include breakpoint(small) {
      font-size: rem(30px);
    }

    @include breakpoint(medium) {
      font-size: rem(35px);
    }

    @include breakpoint(large) {
      font-size: rem(40px);
    }

    @include breakpoint(huge) {
      font-size: rem(41px);
    }
  }
}

h6,
.h6 {
  font-size: rem($h6Size);
}

/**
 * Links
 */
a {
  color: $link;
  word-wrap: break-word;
  transition: 300ms;
  text-decoration: none;

  &:hover {
    color: darken($link, 12%);
  }
  &:focus {
    color: darken($secondary, 50%);
  }
  &:active {
    color: darken($secondary, 30%);
  }

  img {
    border: none;
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: underline;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  background: $medium;
  border: none;
  clear: both;
  height: rem(1px);
  margin: 2rem 0;
  flex-basis: 100%;

  @include breakpoint(small) {
    margin: 3.5rem 0 3rem;
  }

  @include breakpoint(giant) {
    margin: 4.5rem 0 4rem;
  }
}

ul,
ol {
  list-style: none;

  &.styledList {
    li {
      padding-left: rem(25px);
      margin-bottom: rem(11px);
      display: flex;
      align-items: center;

      &:before {
        line-height: 1;
        @extend .icon;
        @extend .icon-angle-right;
        text-indent: rem(-25px);
        color: $beige;
        font-size: 9px;
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: rgba($medium, 0.8);
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      + dt {
        margin-top: rem(10px);
      }

      + dd {
        margin-top: rem(-15px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }

  &.no-width {
    dt {
      width: auto;
      padding-right: 0.7rem;
    }

    dd {
      width: auto;
    }
  }
}

ol {
  &.styledList {
    counter-reset: item;

    li {
      counter-increment: item;

      &:before {
        content: counters(item, ".") ". ";
      }
    }

    ol {
      margin-bottom: 0;

      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.']
  {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}
