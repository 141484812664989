/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 16px;

$baseLineHeight: 26px;

$h1Size: 32px;

$h2Size: 28px;

$h3Size: 24px;

$h4Size: 22px;

$h5Size: 18px;

$h6Size: 16px;

$mainFont: "Libre Baskerville", Georgia, serif;

$displayFont: "Noto sans JP", sans-serif;

$special: "Pinyon Script", sans-serif;

$iconFont: icomoon;

// Project colors

$primary: #726A5B;
$secondary: #EDE8DD;
$light: #fff;
$medium: #F2F2F0;
$dark: #131313;
$border: #bbb;
$alert: #D85D00;
$link: #c3a239;
$beige-light: #f4ecd3;
$beige: #e0cb8b;

$baseGap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "\e909", 
	minus: "\e906", 
	plus: "\e907", 
	angle-right: "\e900", 
	angle-left: "\e901", 
	angle-up: "\e902", 
	angle-down: "\e903", 
	exclamation: "\e905", 
	search: "\e908", 
	clock: "\e904", 
	calendar: "\e90b", 
	phone: "\e90a", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
	),
	tiny: (
		width: em(480px, $bpContext), 
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10),
		prefix: (1, 2, 3),
	),
	medium: (
		width: em(760px, $bpContext), 
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10),
		suffix: (1),
		prefix: (1, 2, 3),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (3, 4),
		suffix: (1),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10),
		prefix: (1, 2, 3),
	),
	full: (
		width: em(1520px, $bpContext), 
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (4, 5),
		prefix: (1, 2),
		suffix: (0),
	),
	cut-after: (
		width: em(1640px, $bpContext), 
	),
	never-happen: (
		width: em(90000000000000px, $bpContext), 
	),
);

